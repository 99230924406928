import { useMutation, useQueryClient } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import decamelize from "decamelize-keys";
import { toast } from "vue3-toastify";
import { FetchError } from "ofetch";
import { useAccessToken } from "~/domains/member/composables";
import { memberQueries } from "~/domains/member/queries";

export function useLoginMutation() {
  const queryClient = useQueryClient();
  const api = useApi();

  return useMutation({
    mutationFn: async (body: { email: string; password: string }) => {
      const data = await api<{ access_token: string; member: { id: number } }>(
        "/v1/auth/login",
        {
          method: "POST",
          body,
        },
      );

      return camelize(data, { deep: true });
    },
    onError: async () => {
      toast.error("Invalid email or password");
    },
    async onSuccess(data) {
      const token = useAccessToken();
      token.value = data.accessToken;

      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: memberQueries.self().queryKey,
        }),
        queryClient.invalidateQueries({
          queryKey: memberQueries.detail(data.member.id).queryKey,
        }),
      ]);
    },
  });
}

export const useLogoutMutation = () => {
  const queryClient = useQueryClient();
  const api = useApi();
  const token = useAccessToken();
  const router = useRouter();

  return useMutation({
    mutationFn: async () => {
      try {
        await api("/v1/auth/logout", {
          method: "POST",
          body: {
            token: token.value,
          },
        });
      } catch (e) {
        if (e instanceof FetchError) {
          if (e.response?.status === 401) {
            token.value = null;
            return null;
          }
        }

        throw e;
      }
    },
    onSuccess: async () => {
      token.value = null;
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: memberQueries.self().queryKey,
        }),
        router.push("/"),
      ]);
    },
  });
};

export function useRequestResetCodeMutation() {
  const api = useApi();

  return useMutation({
    mutationFn: async (body: { email: string }) => {
      const data = await api<{ error: boolean }>(
        "/v1/auth/request_reset_code",
        {
          method: "POST",
          body,
        },
      );

      return camelize(data, { deep: true });
    },
  });
}

export function useVerifyResetCodeMutation() {
  const api = useApi();

  return useMutation({
    mutationFn: async (body: { email: string; code: string }) => {
      const data = await api<{ error: boolean }>(
        "/v1/auth/verify_password_token",
        {
          method: "POST",
          body: {
            token: body.code,
            email: body.email,
          },
        },
      );

      return camelize(data, { deep: true });
    },
  });
}

export function useChangePasswordFromTokenMutation() {
  const api = useApi();

  return useMutation({
    mutationFn: async (body: {
      email: string;
      code: string;
      newPassword: string;
    }) => {
      const data = await api<{ message: string }>(
        "/v1/auth/change_password_from_token",
        {
          method: "POST",
          body: decamelize(
            {
              email: body.email,
              newPassword: body.newPassword,
              token: body.code,
            },
            { deep: true },
          ),
        },
      );

      return camelize(data, { deep: true });
    },
  });
}

export function useAdminLoginAsMemberMutation() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params: { email: string; token: string }) => {
      const data = await api<{ access_token: string }>("/v1/auth/login/admin", {
        method: "POST",
        body: {
          email: params.email,
        },
        headers: {
          Authorization: `Bearer ${params.token}`,
        },
      });

      return camelize(data, { deep: true });
    },
    onSuccess(data) {
      const token = useAccessToken();
      token.value = data.accessToken;
      return queryClient.invalidateQueries(memberQueries.self());
    },
  });
}
